import './Oups.css' ;
import {
  Banner,
  Footer
} from '../components' ;

export default function Oups() {
    return (
        <div className="Oups">
            <Banner/>
            <div className="Oups-content">
                <h1>Oups !</h1>
                <p>Le site est en cours de construction...</p>
                <p>
                    Si vous souhaitez plus d'informations, envoyez un message
                    à l'adresse suivante : <a href='mailto:contact@dynamiquemarine.fr'>contact@dynamiquemarine.fr</a>
                </p>
            </div>
            <Footer/>
        </div>
    ) ;
}