import './App.css' ;
import {
  HashRouter as Router,
  Route,
  Switch
} from 'react-router-dom' ;
import {
  Oups,
  Legal
} from './pages' ;

export default function App() {
  return (
    <div className='App'>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path='/'       exact component={() => <Oups    />} />
          <Route path='/legal'  exact component={() => <Legal   />} />
        </Switch>
      </Router>
    </div>
  );
}
