import './Legal.css' ;
import {
  Banner,
  Footer
} from '../components' ;

export default function Legal() {
    return (
        <div className="Legal">
            <Banner/>
            <div className="Legal-content">
                <h1>Mentions légales</h1>

                <p>Dynamique Marine</p>
                <p>SIRET 89527002300016</p>
                <p><a href="mailto:contact@dynamiquemarine.fr">contact@dynamiquemarine.fr</a></p>

                <h2>Cookies</h2>
                <p>Ce site n'utilise pas de cookie.</p>

                <h2>Version du site</h2>
                <p>{process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}</p>
                {/*
                  process.env.REACT_APP_VERSION is printing 'X.Y.Z' trhough a dev server and
                  and would print 'vX.Y.Z' with the same package.json.version input.
                */}
            </div>
            <Footer/>
        </div>
    ) ;
}