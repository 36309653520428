import './Banner.css' ;
import logo from './graphics/logo-robot-spikes/robot-spikes.svg' ;
import { Link } from 'react-router-dom';

export default function Banner() {
    return <div className="Banner">
        <Link to='/'>
            <img src={logo} className='Banner-logo' alt='An AUV logo'/>
        </Link>
        <div className="Banner-text">
            <h1 className='Banner-title'>dynamique marine</h1>
            <p className='Banner-info'>Ingénieur de recherche • Freelance • Robotique sous-marine</p>
        </div>
    </div> ;
}